<template>
  <v-container>
    <BaseScreenHeader
      title="View Post"
      screenInfo="PST-001"
      width="90%"
      :showBack="false"
      :refresh="true"
      @refresh="refreshPage"
    />

    <BaseLoading :loaded="loaded" />
    <v-sheet class="charcoal">
      <v-timeline
        :key="switchNum"
        dense
        v-if="posts.length > 0"
        :class="isPhone ? 'ml-n6' : ''"
      >
        <v-timeline-item
          color="progressActive"
          fill-dot
          v-for="item in posts"
          :key="item.id"
        >
          <template v-slot:icon>
            <v-icon v-if="item.code == 'EXERCISE_NEW_PR'" color="charcoal">
              mdi-trophy-award
            </v-icon>
            <v-icon
              v-if="item.code == 'WORKOUT_FINISHED_LIFTING'"
              color="charcoal"
              class="link"
            >
              mdi-weight-lifter
            </v-icon>
          </template>
          <template v-slot:opposite>
            <span class="caption purpleAccent--text">
              {{ prettyDate(item.created_local) }}
            </span>
          </template>
          <div class="ml-n5">
            <v-card rounded flat class="pa-1 pb-0 px-2 charcoal">
              <v-card-actions
                :class="textSizeXSmall + ' py-0 px-0 purpleAccent--text'"
              >
                {{ prettyDateTime(item.created_local) }}
                <v-spacer />
                <v-icon
                  v-if="canEdit(item)"
                  color="silver"
                  @click="deleteEvent(item)"
                >
                  mdi-trash-can
                </v-icon>
              </v-card-actions>
              <v-card-text class="pa-0">
                <v-menu
                  v-model="item.preview_profile"
                  :close-on-content-click="false"
                  :nudge-width="150"
                  offset-overflow
                  right
                  offset-x
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      @click="previewProfile(item)"
                      class="progressActive--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.display_name }}
                    </span>
                  </template>
                  <v-card
                    outlined
                    class="py-2 charcoal lighten-1 silver--text"
                    rounded
                    v-if="profileLoaded"
                  >
                    <v-card-title class="paper--text pa-0 pl-2 my-0">
                      {{ profile.display_name }}
                    </v-card-title>
                    <v-card-text class="silver--text pa-0 pl-2">
                      {{ profile.sex }}, age {{ profile.age }}
                    </v-card-text>
                    <v-card-actions class="pa-0 pl-2">
                      <v-avatar
                        :title="profile.display_name"
                        color="progressActive"
                        :size="isPhone ? 80 : 150"
                        tile
                      >
                        <v-img
                          v-if="profile.picture_cdn != null"
                          :alt="profile.display_name"
                          :src="resizedName(profile.picture_cdn, '100')"
                          contain
                          :width="isPhone ? 80 : 100"
                        />
                        <span
                          :class="
                            textSizeLarge + ' progressActive charcoal--text '
                          "
                          v-else
                        >
                          {{ initials(profile.display_name) }}
                        </span>
                      </v-avatar>
                      <v-card class="transparent" flat> </v-card>
                    </v-card-actions>
                    <v-card-actions class="pa-0">
                      <BaseActionButton
                        icon=""
                        text
                        color="progressActive"
                        label="Profile"
                        title="View Profile"
                        @clickedThis="showProfile(profile.id)"
                      />
                    </v-card-actions>
                  </v-card>
                </v-menu>
                <span v-html="item.details" class="paper--text" />
              </v-card-text>
              <v-card-actions class="pa-0 mt-1">
                <span :class="textSize + ' silver--text ml-1'">
                  <span class="link" @click="like(item)">
                    <v-icon
                      :color="item.liked == 'Yes' ? 'paper' : 'silver'"
                      large
                      class="mt-n2"
                    >
                      mdi-thumb-up-outline
                    </v-icon>
                    <span class="ml-n1"> {{ item.up }}</span>
                  </span>
                </span>
                <span class="link" @click="showComments(item, 'event')">
                  <v-icon color="silver" class="ml-2 mt-n2" large>
                    mdi-comment-text-outline
                  </v-icon>
                  <span :class="textSize + ' silver--text ml-n1'">
                    {{ item.comments }}
                  </span>
                </span>
                <v-spacer v-if="isPhone" />
              </v-card-actions>
              <template v-if="item.showComments">
                <v-card-text class="silver--text pa-0 ">
                  <tiptap-vuetify
                    dark
                    v-model="item.newComment"
                    :extensions="extensions"
                    placeholder="Enter new comment"
                    :toolbar-attributes="{ color: 'charcoal', dark: true }"
                    :card-props="{
                      flat: true,
                      class: 'charcoal lighten-1 silver--text'
                    }"
                  />
                </v-card-text>
                <v-card-actions
                  v-if="checkComment(item.newComment)"
                  class="pa-0 mr-n2"
                >
                  <v-spacer />
                  <BaseActionButton
                    color="paper"
                    icon=""
                    plain
                    label="+ Add Comment"
                    @clickedThis="addComment(item, 'event')"
                  />
                </v-card-actions>
                <div class="mt-2">
                  <v-card
                    class="mt-1 charcoal"
                    tile
                    flat
                    v-for="(comment, index) in item.loadedComments"
                    :key="index"
                  >
                    <v-card-actions class="pa-0">
                      <a
                        class="caption mr-1 progressActive--text"
                        :href="'/profile/' + comment.profileid"
                        :title="comment.display_name"
                      >
                        {{ comment.display_name }}
                      </a>
                      <span class="caption silver--text">
                        on {{ prettyDateTime(comment.created_local) }}
                      </span>
                      <v-spacer />
                    </v-card-actions>

                    <v-card-text
                      v-html="comment.comment"
                      class="pa-0 ml-2 charcoal silver--text"
                    >
                    </v-card-text>
                    <v-card-actions class="pa-0 mt-n4 ml-2">
                      <span @click="likeComment(comment)" class="link">
                        <v-icon
                          small
                          :color="comment.liked == 'Yes' ? 'paper' : 'silver'"
                          class="mt-n2"
                        >
                          mdi-thumb-up-outline
                        </v-icon>
                        <span :class="textSizeSmall + ' silver--text ml-n1'">
                          {{ comment.likes }}
                        </span>
                      </span>
                      <span @click="replyToComment(comment)" class="link ml-2">
                        <v-icon small color="silver" class="mt-n1">
                          mdi-comment-plus-outline
                        </v-icon>
                        <span :class="textSizeXSmall + ' silver--text ml-n1'">
                          Reply
                        </span>
                      </span>

                      <v-spacer />
                      <v-icon
                        small
                        v-if="canEdit(comment)"
                        color="silver"
                        @click="deleteComment(item, comment)"
                      >
                        mdi-trash-can
                      </v-icon>
                    </v-card-actions>
                    <template v-if="comment.showReply">
                      <tiptap-vuetify
                        class="caption ml-4"
                        dark
                        v-model="comment.reply"
                        :extensions="extensions"
                        placeholder="Reply to comment"
                        :toolbar-attributes="{
                          color: 'charcoal',
                          dark: true
                        }"
                        :card-props="{
                          flat: true,
                          class: 'charcoal lighten-1 silver--text'
                        }"
                      />
                      <v-card-actions
                        v-if="checkComment(comment.reply)"
                        class="pa-0 mr-n2"
                      >
                        <v-spacer />
                        <BaseActionButton
                          color="paper"
                          icon=""
                          plain
                          label="+ Reply"
                          @clickedThis="reply(comment, item)"
                        />
                      </v-card-actions>
                    </template>
                    <v-card
                      class="mt-n ml-4 charcoal"
                      tile
                      flat
                      v-for="(reply, index) in comment.replies"
                      :key="index"
                    >
                      <v-card-actions class="pa-0 silver--text">
                        <v-icon x-small color="silver">
                          mdi-subdirectory-arrow-right
                        </v-icon>
                        <a
                          class="caption mr-1 progressActive--text"
                          :href="'/profile/' + reply.profileid"
                          :title="reply.display_name"
                        >
                          {{ reply.display_name }}
                        </a>
                        <span class="caption silver--text">
                          on {{ prettyDateTime(reply.created_local) }}
                        </span>
                        <v-spacer />
                      </v-card-actions>

                      <v-card-text
                        v-html="reply.comment"
                        class="pa-0 ml-2 charcoal silver--text"
                      >
                      </v-card-text>
                      <v-card-actions class="pa-0 mt-n4 ml-2">
                        <span @click="likeComment(reply)">
                          <v-icon
                            small
                            :color="reply.liked == 'Yes' ? 'paper' : 'silver'"
                          >
                            mdi-thumb-up-outline
                          </v-icon>
                          <span :class="textSizeSmall + ' silver--text ml-0'">
                            {{ reply.likes }}
                          </span>
                        </span>
                        <v-spacer />
                        <v-icon
                          small
                          v-if="canEdit(reply)"
                          color="silver"
                          @click="deleteReply(reply, comment, item)"
                        >
                          mdi-trash-can
                        </v-icon>
                      </v-card-actions>
                    </v-card>

                    <v-divider class="charcoal lighten-1 mt-2" />
                  </v-card>
                </div>
              </template>
            </v-card>
          </div>
        </v-timeline-item>
      </v-timeline>
    </v-sheet>
  </v-container>
</template>
<script>
import { validationMixin } from 'vuelidate'
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import debounce from 'lodash/debounce'

import {
  TiptapVuetify,
  Bold,
  Italic,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  HardBreak
} from 'tiptap-vuetify'

export default {
  components: { TiptapVuetify },
  name: 'ViewPost',
  mixins: [validationMixin, util],
  data() {
    return {
      switchNum: 0,
      loaded: false,
      isActive: false,
      showWorkoutDialog: false,
      showDeleteDialog: false,
      showStats: false,
      showInvitesDialog: false,
      showExerciseInfo: false,
      invites: [],
      volumeStats: {},
      isUserScrolling: false,
      isNow: true,
      selectedWorkout: null,
      exerciseid: null,
      metric: 0,
      imperial: 0,
      flips: 0,
      search: '',
      selectedMeasure: [],
      measureList: [],
      posts: [],
      news: [],
      profile: null,
      datetime: null,
      debouncedScroll: null,
      debounce: null,
      pagesize: 50,
      currentPage: 0,
      totalCount: 100, //fetch from API
      bottom: false,
      reload: false,
      profileLoaded: false,
      extensions: [
        Paragraph,
        Bold,
        Italic,
        Link,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak
      ]
    }
  },
  props: {
    itemid: {
      type: String,
      default: null
    },
    itemType: {
      type: String,
      default: 'event'
    }
  },
  beforeMount() {
    this.loadPage()
  },

  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100)
    window.addEventListener('scroll', this.handleDebouncedScroll)
  },
  beforeDestroy() {
    // I switched the example from `destroyed` to `beforeDestroy`
    // to exercise your mind a bit. This lifecycle method works too.
    window.removeEventListener('scroll', this.handleDebouncedScroll)
  },

  watch: {
    bottom(bottom) {
      if (bottom) {
        this.currentPage++
        this.loadPage()
      }
    }
  },
  computed: {
    itemsCount() {
      return this.posts.length
    },

    ...appConfig
  },
  methods: {
    cancelInfo() {
      this.exerciseid = null
      this.showExerciseInfo = false
    },
    checkComment(newComment) {
      if (!newComment) return false
      let noHTML = newComment.replace(/<[^>]*>?/gm, '')
      return noHTML.length >= 2
    },
    refreshPage() {
      this.loaded = false
      this.currentPage = 0
      this.reload = true
      this.loadPage()
    },
    replyToComment(comment) {
      comment.showReply = !comment.showReply
    },
    showComments(item, type) {
      item.showComments = !item.showComments
      if (item.showComments) this.loadComments(item, type)
    },
    hideComments(item) {
      item.showComments = false
    },
    showInvites() {
      this.showInvitesDialog = true
    },
    cancelInvites() {
      this.showInvitesDialog = false
    },
    loadComments(item, type) {
      let pagination = '/' + item.currentPage + '/' + item.pagesize
      return axios
        .get(
          this.baseURL + '/comments/' + type + '/' + item.id + pagination,
          {}
        )
        .then(response => {
          if (response.status == 200) {
            response.data.data.forEach(element => {
              element.showReply = false
              element.reply = ''
              element.replies = JSON.parse(element.replies)
            })
            item.loadedComments = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    setResults(value) {
      this.posts = value
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.bottom = this.bottomVisible()
    },
    showWorkout(value) {
      this.selectedWorkout = value.data.workoutid
      this.showWorkoutDialog = true
    },
    showProfile(profileid) {
      this.$router.push('/profile/' + profileid)
    },
    cancel() {
      this.selectedWorkout = null
      this.showWorkoutDialog = false
    },
    previewProfile(item) {
      this.profileLoaded = false
      return axios
        .get(this.baseURL + '/user/public/profile/' + item.data.profileid, {})
        .then(response => {
          if (response.status == 200) {
            this.profile = response.data.data
            this.profileLoaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    likeComment(comment) {
      if (comment.liked != 'Yes') {
        return axios
          .post(this.baseURL + '/comment/like', {
            commentid: comment.id,
            now: this.now()
          })
          .then(response => {
            if (response.status == 200) {
              comment.likes++
              comment.liked = 'Yes'
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      } else {
        return axios
          .post(this.baseURL + '/comment/unlike', { commentid: comment.id })
          .then(response => {
            if (response.status == 200) {
              comment.likes--
              comment.liked = 'No'
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },

    reply(comment, item) {
      return axios
        .post(this.baseURL + '/comment/reply', {
          comment: comment,
          now: this.now()
        })
        .then(response => {
          if (response.status == 200) {
            let reply = JSON.parse(response.data.data)
            if (comment.replies != null) comment.replies.unshift(reply)
            else {
              comment.replies = []
              comment.replies[0] = reply
            }
            item.comments++
            this.toast('Reply Added!')
            comment.reply = ''
            comment.showReply = false
            comment.num_replies++
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    addComment(item, type) {
      return axios
        .post(this.baseURL + '/comment', {
          item: item,
          type: type,
          now: this.now()
        })
        .then(response => {
          if (response.status == 200) {
            let comment = JSON.parse(response.data.data)
            item.loadedComments.unshift(comment)
            this.toast('Comment Added!')
            item.newComment = ''
            item.comments++
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    deleteEvent(event) {
      return axios
        .delete(this.baseURL + '/event/' + event.id)
        .then(response => {
          if (response.status == 200) {
            this.posts = this.posts.filter(el => {
              return el.id != event.id
            })
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    deleteReply(reply, comment, item) {
      return axios
        .delete(this.baseURL + '/comment/reply/' + reply.id)
        .then(response => {
          if (response.status == 200) {
            comment.num_replies--
            item.comments--
            comment.replies = comment.replies.filter(el => {
              return el.id != reply.id
            })
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    deleteComment(item, comment) {
      return axios
        .delete(this.baseURL + '/comment/event/' + comment.id + '/' + item.id)
        .then(response => {
          if (response.status == 200) {
            item.loadedComments = item.loadedComments.filter(el => {
              return el.id != comment.id
            })
            item.comments--
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    like(event) {
      if (event.liked == 'Yes') event.up--
      else event.up++
      return axios
        .post(this.baseURL + '/event/like', { event: event, now: this.now() })
        .then(response => {
          if (response.status == 200) {
            let alreadyLiked = response.data.data
            if (alreadyLiked == 'false') {
              event.liked = 'Yes'
            } else {
              event.liked = 'No'
            }
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadPage() {
      return axios
        .get(this.baseURL + '/post/' + this.itemType + '/' + this.itemid, {})
        .then(response => {
          if (response.status == 200) {
            let responseData = response.data.data
            if (this.itemType == 'event')
              responseData.forEach(element => {
                element.data = JSON.parse(element.data)
                element.showComments = false
                element.pagesize = this.pagesize
                element.currentPage = 0
                element.newComment = ''
                element.loadedComments = []
                element.preview_profile = false
              })
            if (this.itemType == 'comment')
              responseData.forEach(element => {
                element.replies = JSON.parse(element.replies)
                element.showComments = false
                element.pagesize = this.pagesize
                element.currentPage = 0
                element.newReply = ''
                element.preview_profile = false
              })
            this.posts = responseData

            this.loaded = true
            this.reload = false
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
